import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Home from "../components/flows/home"

import MetaImage from "../assets/img/seo/wefit-data-driven-design.png"

import "./index.css"

const IndexPage = (props) => {

const {location} = props;

return (
    <Layout originPage="Página principal" location={location}>
      <SEO
        title="WeFit"
        description="Velocidade e precisão em produtos e servicos digitais"
        location={location}
        image={{
          src: MetaImage,
          height: 1080,
          width: 1920,
        }}
      />
      <Home location={location}></Home>    
    </Layout>
)
}

export default IndexPage
